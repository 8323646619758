import React from 'react'

import { Container, Row, Col } from 'reactstrap'
import Layout from '../components/Layout'
import Seo from '../components/seo'

import Hero from '../components/Hero'

import '../scss/page-faq.scss'

const faqPage = () => (
  <Layout>
    <Seo title="Faq | Talk of the Town" />

    <Hero text="FAQ" />
    <section className="images-container faq">
      <Container fluid>
        <Row>
          <Col
            sm="12"
            style={{
              backgroundImage: `url(${require('../images/imagem_1.jpeg')})`,
            }}
          />
        </Row>
      </Container>
    </section>
    <section className="questions">
      <Container>
        <Row>
          <Col sm="12" className="question">
            <h3>Talk of the Town é um curso de inglês?</h3>
            <p>
              Não. Talk of the Town é uma escola de idiomas com
              metodologia baseada em debates e cursos sobre temas
              contemporâneos. Nosso carro chefe são os Debates in
              English, mas temos também Débats em Français, Talks
              &amp; Courses ao vivo e on-demand em várias outras
              línguas, para você praticar enquanto estuda e
              discute assuntos de seu interesse. O aperfeiçoamento
              do idioma acontece em paralelo ao seu crescimento
              intelectual e pessoal.
            </p>
          </Col>
          <Col sm="12" className="question">
            <h3>
              Será que meu inglês é suficiente para o Talk of the
              Town?
            </h3>
            <p>
              Provavelmente sim. Qualquer pessoa com domínio
              intermediário do inglês pode participar. A grande
              maioria tem melhores noções do idioma do que imagina
              e precisa, apenas, praticar. No método Talk of the
              Town, tão importante quanto saber falar é o que você
              tem a dizer. Temos grupos de níveis Intermediate e
              Advanced, com material de estímulo preparado de
              acordo com eles. Ao se inscrever, você participa de
              um debate grátis em nível Intermediate, para
              avaliação mútua. Assim, poderemos indicar o grupo
              mais adequado para você.
            </p>
          </Col>
          <Col sm="12" className="question">
            <h3>Qual é o Perfil dos Participantes?</h3>
            <p>
              Isto depende do grupo. As turmas são reduzidas e
              homogêneas, mas de perfis variados: adolescentes,
              senhoras, executivos, terceira idade, pais de alunos
              de escolas bilíngues. Nossa experiência mostra que é
              mais produtivo reunir pessoas com interesses
              semelhantes. Por isso, é muito importante que você
              escolha um grupo de acordo com o seu perfil.
              Juntando dois amigos, você pode abrir uma turma.
            </p>
          </Col>
          <Col sm="12" className="question">
            <h3>Qual é a origem do Talk of the Town?</h3>
            <p>
              O método que deu origem ao Talk of the Town -
              Improve, English as a Second Language - foi criado
              na década de 1990, testado e aprovado nos Estados
              Unidos por estrangeiros de diversas partes do mundo.
              Em 1992, obteve o US Copyright Office Registration
              Certificate. Desde 2006 funcionamos em Ipanema, Rio
              de Janeiro, acompanhando os acontecimentos do mundo
              contemporâneo, semana após semana. Em 2020,
              inauguramos a nossa versão online.
            </p>
          </Col>
          <Col sm="12" className="question">
            <h3>Qual é a formação dos Professores?</h3>
            <p>
              Nossos mediadores de debates são, na maioria,
              nativos e têm experiência mínima de 2 anos com o
              método. Esses profissionais têm expertise na língua
              inglesa, comprovada por diplomas de proficiência de
              universidades internacionais, e formação específica
              em ciências sociais. Isso garante a você o acesso a
              temas sempre atuais, que despertam naturalmente a
              necessidade de expressão.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
)

export default faqPage
